
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    message: String,
  },
})
export default class Introduction extends Vue {
  activetab = 0;

  message!: string;

  changeTab(index: number) {
    this.activetab = index;
    console.log(this.activetab);
  }
}
