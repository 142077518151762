import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Introduction = _resolveComponent("Introduction")!
  const _component_Technologies = _resolveComponent("Technologies")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Introduction, { message: "VIPIN MERAVI" }),
    _createVNode(_component_Technologies)
  ]))
}