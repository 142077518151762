
import { Options, Vue } from 'vue-class-component';
import * as THREE from 'three';

@Options({
  props: {
    message: String,
  },
})
export default class Introduction extends Vue {
  message!: string;

  cube!: any;

  scene!: any;

  renderer!: any;

  camera!: any;

  mounted() {
    this.createTheScene();
  }

  createTheScene() {
    this.scene = new THREE.Scene();
    const screenRatio = window.innerWidth / window.innerHeight;
    this.camera = new THREE.PerspectiveCamera(75, screenRatio, 0.1, 1000);
    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    const documentDiv = document.getElementById('first-3d');
    documentDiv?.appendChild(this.renderer.domElement);

    // Create a ground plane
    const groundGeometry = new THREE.PlaneGeometry(10, 10);
    const groundMaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    const ground = new THREE.Mesh(groundGeometry, groundMaterial);
    this.scene.add(ground);

    const geometry = new THREE.BoxGeometry(1, 1, 1);
    const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    this.cube = new THREE.Mesh(geometry, material);
    this.scene.add(this.cube);
    this.camera.position.z = 5;
    this.animate();
  }

  animate() {
    requestAnimationFrame(this.animate);
    this.cube.rotation.x += 0.01;
    this.cube.rotation.y += 0.01;
    this.renderer.render(this.scene, this.camera);
  }
}
