
import { onMounted } from 'vue';
import { Options, Vue } from 'vue-class-component';

@Options({
  props: { message: String },
})
export default class Introduction extends Vue {
  activetab = 1;

  mounted() {
    console.log('this', this);
  }

  changeTab(index: number) {
    this.activetab = index;
    console.log(this.activetab);
  }
}
