
import { Options, Vue } from 'vue-class-component';
import Introduction from '@/components/Introduction.vue'; // @ is an alias to /src
import ShortDescription from '@/components/ShortDescription.vue';
import Technologies from '@/components/Technologies.vue';

@Options({
  components: {
    Introduction,
    ShortDescription,
    Technologies,
  },
})
export default class HomeView extends Vue {}
